import axios from 'axios';
import { decodeJwt } from 'jose';
import { destroyCookie, parseCookies } from 'nookies';

/**
 * axios instance
 */

axios.defaults.responseType = 'json';

const API = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

API.interceptors.request.use(
  async (config) => {
    // const token = currentUser?.signInUserSession?.idToken?.jwtToken;
    // if (token) {
    //   config.headers.Authorization = `Bearer ${token}`;
    // }

    const token = parseCookies(null).auth_token?.split(' ')[1];

    if (token) {
      const expiryTime = decodeJwt(token).exp || 0;

      if (expiryTime < Math.floor(Date.now() / 1000)) {
        destroyCookie(null, 'auth_token', { path: '/' });
        window.location.reload();
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    return response?.data;
  },
  (error) => {
    return Promise.reject(error.response);
  }
);

export { API };
