import { apiErrors } from '../apiErrors';

const format = (e: any): string => {
  const knownErrors = Object.keys(apiErrors);

  let message: string = e?.data?.message
    ? e.data.message
    : 'Something went wrong';

  if (knownErrors.includes(e?.data?.message)) {
    message = apiErrors[e.data.message]!;
  }

  if (e?.data?.errors && e.data.errors.length !== 0) {
    message = `${message} ${e.data.errors.join(', ')}`;
  }

  return message;
};

export const ErrorFormatter = { format };
