import '../styles/global.css';
import '@wooltrakey/meta-pylon-3d/dist/3d.css';
import 'react-toastify/dist/ReactToastify.css';

import { captureException } from '@sentry/nextjs';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { appWithTranslation, i18n } from 'next-i18next';
import { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { toast, ToastContainer } from 'react-toastify';

import { AuthContextProvider } from '@/context/AuthContext';
import { Web3AccountsProvider } from '@/context/Web3Accounts';
import { useServiceWorker } from '@/hooks/useServiceWorker';
import { ErrorFormatter } from '@/utils/api/client';
import * as ga from '@/utils/ga';

const MyApp = ({ Component, pageProps }: AppProps) => {
  useServiceWorker();
  const router = useRouter();

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
      mutations: {
        onError: (e: any) => {
          // if error related to metamask occurs
          if (e.code) {
            if (e.code !== 4001) {
              // 4001 status code is for user rejected request https://eips.ethereum.org/EIPS/eip-1193#provider-errors
              captureException(e);
              toast(i18n?.t('Something went wrong'), {
                type: 'error',
                hideProgressBar: true,
                toastId: 'error',
              });
            }
          } else {
            const errorMessage = ErrorFormatter.format(e);
            toast(i18n?.t(errorMessage), {
              type: 'error',
              hideProgressBar: true,
              toastId: 'error',
            });
          }
        },
      },
    },
  });

  useEffect(() => {
    const handleRouteChange = (url: any) => {
      ga.pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <AuthContextProvider>
          <Web3AccountsProvider>
            <Component {...pageProps} />
            <ToastContainer limit={1} />
          </Web3AccountsProvider>
        </AuthContextProvider>
      </QueryClientProvider>
      <Script
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
      />

      <Script strategy="lazyOnload" id="googleAnalytics">
        {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
                    page_path: window.location.pathname,
                    });
                `}
      </Script>
    </>
  );
};

export default appWithTranslation(MyApp);
