import { useEffect } from 'react';

import { useAuth } from '@/context/AuthContext';
import { API } from '@/utils/api';
import { urlBase64ToUint8Array } from '@/utils/urlBase64ToUint8Array';

// check if browser supports notification
const isSupported = () =>
  'Notification' in window &&
  'serviceWorker' in navigator &&
  'PushManager' in window;

export const useServiceWorker = () => {
  const { hasToken } = useAuth();
  useEffect(() => {
    (async () => {
      if (!isSupported()) return;
      if (!hasToken || Notification.permission !== 'granted') return;

      const register = await navigator.serviceWorker.register('/sw.js', {
        scope: '/',
      });

      await navigator.serviceWorker.ready;
      const subscription = await register.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(
          process.env.NEXT_PUBLIC_VAPID_KEY!
        ),
      });

      API.post('/user/subscribe', subscription);
    })();
  }, [hasToken]);
};
