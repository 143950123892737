import Web3 from 'web3';

const getEthAccount = async () => {
  const { ethereum } = window;
  const accounts = await ethereum?.request({
    method: 'eth_accounts',
  });
  return accounts;
};

const changeNetwork = async () => {
  const { ethereum } = window;
  if (ethereum) {
    const appNetwork = process.env.NEXT_PUBLIC_ETH_NETWORK;
    if (ethereum.chainId !== appNetwork) {
      // request the network change
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: appNetwork }],
      });
    }
  }
};

/**
 * Wait for certain number of block confirmations
 * before learning that the transactions completed.
 */
const DEFAULT_INTERVAL = 500;
const DEFAULT_BLOCKS_TO_WAIT = 3;

const waitForTransaction = (txnHash: string) => {
  const interval = DEFAULT_INTERVAL;
  const blocksToWait = DEFAULT_BLOCKS_TO_WAIT;
  const web3 = new Web3(Web3.givenProvider);
  const transactionReceiptAsync = async (
    hash: string,
    resolve: any,
    reject: any
  ) => {
    try {
      const receipt = await web3.eth.getTransactionReceipt(hash);
      try {
        const block = await web3.eth.getBlock(receipt.blockNumber);
        const current = await web3.eth.getBlock('latest');
        if (current.number - block.number >= blocksToWait) {
          const txn = await web3.eth.getTransaction(txnHash);
          if (!receipt.status) {
            reject(new Error('transaction was not successfull'));
            return;
          }
          if (txn.blockNumber != null) resolve(receipt);
          else
            reject(
              new Error(
                `Transaction with hash: ${txnHash} ended up in an uncle block.`
              )
            );
        } else
          setTimeout(
            () => transactionReceiptAsync(txnHash, resolve, reject),
            interval
          );
      } catch (e) {
        setTimeout(
          () => transactionReceiptAsync(txnHash, resolve, reject),
          interval
        );
      }
    } catch (e) {
      reject(e);
    }
  };

  return new Promise((resolve, reject) => {
    transactionReceiptAsync(txnHash, resolve, reject);
  });
};

export const web3Util = { getEthAccount, changeNetwork, waitForTransaction };
