export const apiErrors: { [key: string]: string } = {
  MORE_THAN_THREE_PYLONS: "Can't have more than three pylons.",
  ALREADY_HAVE_THREE_PYLONS:
    "You already have three META PYLONs. You can't have more than three.",
  MORE_THAN_HUNDRED_PYLONS: "Can't have more than hundred pylons.",
  MISSING_AUTH_TOKEN: 'Request missing auth token',
  JWT_PARSE: 'Error parsing jwt',
  NOT_ALLOWED:
    'You are not in our whitelist. Please contact us if the issue persists',
  UNAUTHORIZED: 'Not authorized',
  ALREADY_DESIGNED: 'Pylon is already designed.',
  USER_NOT_FOUND: 'User does not exist',
  STICKER_ALREADY_PRESENT: 'Sticker added is already present in another pylon',
  NOT_IN_QUEUE: 'User is not in queue',
  NOT_USERS_TURN: 'Not your turn',
  ALREADY_MINTING: 'A pylon is already minting',
  NO_WAITING_PYLON: 'No pylons in waiting mint status',
  'Validation errors': 'Validation error occurred.',
};
