import React, { createContext, useContext, useEffect, useState } from 'react';

import { web3Util } from '@/utils/web3';

import { getToken } from './AuthContext';

export interface IWeb3AccountContext {
  accounts: string[];
  setAccounts: (ac: string[]) => void;
}

export const Web3AccountsContext = createContext<IWeb3AccountContext>({
  accounts: [],
  setAccounts: () => {},
});

interface ParseTokenRes {
  walletAddress: string;
  id: string;
}
const parseToken = (token: string): ParseTokenRes | undefined => {
  const t = token.split('Bearer ')?.[1]?.trim();
  if (t) {
    const payload = t.split('.')?.[1];
    const payloadStr = Buffer.from(payload ?? '', 'base64').toString();
    return JSON.parse(payloadStr);
  }
  return undefined;
};

export const Web3AccountsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [accounts, setAccounts] = useState<string[]>([]);
  // const { logoutUser } = useAuth();
  useEffect(() => {
    const initGetAccount = async () => {
      const ac = await web3Util.getEthAccount();
      const token = getToken();
      const tokenContent = parseToken(token);
      if (ac && tokenContent?.walletAddress === ac[0]) {
        setAccounts(ac);
      }
    };
    initGetAccount();
  }, []);

  useEffect(() => {
    const { ethereum } = window;
    const acChanged = (ac: string[]) => {
      const currentAccount = ac[0];
      const token = getToken();
      const tokenContent = parseToken(token);
      if (!token) return;
      if (currentAccount === tokenContent?.walletAddress) {
        setAccounts(ac);
      }
      // logoutUser();
    };
    ethereum?.on('accountsChanged', acChanged);
    return () => ethereum?.removeListener('accountsChanged', acChanged);
  }, []);

  return (
    <Web3AccountsContext.Provider value={{ accounts, setAccounts }}>
      {children}
    </Web3AccountsContext.Provider>
  );
};

export const useWeb3Accounts = () => {
  const ctx = useContext(Web3AccountsContext);

  return {
    ...ctx,
    hasAccounts: ctx.accounts?.length > 0,
  };
};
