declare global {
  interface Window {
    gtag: any;
  }
}

// log the pageview with their URL
export const pageview = (url: any) => {
  if (window.gtag !== undefined)
    window.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
      page_path: url,
    });
};

// log user with their metamask id
export const sendUserIdToGA = (userId: any) => {
  if (window.gtag !== undefined)
    window.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
      user_id: userId,
    });
};
