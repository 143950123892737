import { useRouter } from 'next/router';
import { destroyCookie, parseCookies, setCookie } from 'nookies';
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

export interface AuthUser {}

interface IAuthContext {
  user?: AuthUser;
}

interface AuthContextProviderProps {
  children: ReactNode;
}

interface CookieObject {
  auth_token?: string;
}

export const AuthContext = createContext<IAuthContext>({});

export const AuthContextProvider: React.FC<AuthContextProviderProps> = ({
  children,
}) => {
  return <AuthContext.Provider value={{}}>{children}</AuthContext.Provider>;
};

export const getToken = () =>
  (parseCookies() as CookieObject)?.auth_token ?? '';

export const useAuth = () => {
  const [token, setToken] = useState<string>();
  const router = useRouter();

  useEffect(() => setToken(getToken));

  const setLoginCookie = (t: string) => {
    setCookie(null, 'auth_token', t, { path: '/' });
    setToken(getToken);
  };

  const logoutUser = () => {
    destroyCookie(null, 'auth_token', { path: '/' });
    setToken(getToken);
    router.push('/');
  };

  const ctx = useContext(AuthContext);
  const hasToken = !!token;

  return {
    ...ctx,
    setLoginCookie,
    logoutUser,
    token,
    hasToken,
  };
};
